import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import AboutDevelopments from '../components/Emprendimientos/about-developments.jsx'
import Inicio from '../components/Emprendimientos/main'
import Steps from '../components/Emprendimientos/steps'
import Investors from '../components/investorsDev'
import BannerTop from "../components/Global/Modules/MediaPush/BannerTop";

const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos"/>
    <BannerTop page="EMP"/>
    <Inicio />
    <AboutDevelopments />
    <Investors />
    {/* <Steps /> */}
  </Layout>
)

export default IndexPage
