import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import { getBrText } from "../helpers/helper.rendering"

import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

const Investors = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
  <section className="investors emprendimientos">
    <div className="container-fluid">
      <div className="row justify-content-between index-1">
        <div className="col-2">
          <div className="line-vertical"></div>
        </div>
        <div className="col-2">
          <div className="line-vertical"></div>
        </div>
        <div className="col-2">
          <div className="line-vertical"></div>
        </div>
        <div className="col-2">
          <div className="line-vertical"></div>
        </div>
        <div className="col-2">
          <div className="line-vertical"></div>
        </div>
      </div>
      <h4>Inversores</h4>
      <div className="row justify-content-center text-center">
        <div className="col-lg-4">
          <div className="item">
            <img src="/images/noun_investment.svg" alt="" />
            <h5 className="pt-5">Grandes Inversores</h5>
            <p>
              Inversores que participen aportando capital para la compra de la
              tierra o para desarrollo de proyectos, para maximizar su
              rentabilidad.
            </p>
            <Link to={"/contacto"} className="btn-square">
              CONTACTANOS
            </Link>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="item">
            <img src="/images/noun_leadership.svg" alt="" />
            <h5 className="pt-5">
              Usuario Finales <br /> e Inversores Minoristas
            </h5>
            <p>
              Empresas o particulares que cuenten con capital para sumarse a
              algunos de los proyectos liderados por la desarrolladora, ya sea
              como inversión como para uso propio.
            </p>
            <Link to={"/contacto"} className="btn-square" >
              CONTACTANOS
            </Link>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="item">
            <img src="/images/noun_land.svg" alt="" />
            <h5 className="pt-5">Dueños de Tierra</h5>
            <p>
              Inversores que participen aportando capital para la compra de la
              tierra o para desarrollo de proyectos, para maximizar su
              rentabilidad
            </p>
            <Link to={"/contacto"} className="btn-square" >
              CONTACTANOS
            </Link>
          </div>
        </div>
      </div>
    </div>
</section>
);
}

export default Investors
