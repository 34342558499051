import React, { useEffect, useState } from 'react' 

import OwlCarousel from 'react-owl-carousel3';
import { getFakeAddress, getFeatured } from '../../helpers/helper.developments';
import { useSelector } from 'react-redux';
import useQueryDevelopments from '../../hooks/useQueryDevelopments';
import { Link } from 'gatsby';

export default function Main() {
    const [active,setActive] = useState(0)
    // const {data} = useSelector(store => store.developments.developments)
    const { allDevelopmentsData, isLoading } = useQueryDevelopments();
    const [featured,setFeatured] = useState() 

    console.log((allDevelopmentsData))

    useEffect(() => {
        if(allDevelopmentsData){
            setFeatured(getFeatured(allDevelopmentsData))
        }
    },[allDevelopmentsData])

  return !isLoading ? (
    <section className="main emprendimientos">
            <div className="content-image">
                {(getFeatured(allDevelopmentsData)).map((item,index) =>(
                    <img className={'animate__animated animate__fadeIn animate__fast ' + (index == active ? 'd-block' : 'd-none')} src={(item.photos).find(element => element.is_front_cover === true).original} alt="" />
                ))}
            </div>
            <div className="container-fluid full-container">
                <div className="content-slider d-flex justify-content-start align-items-center">
                    {featured && <div className="item active d-flex flex-column align-items-start animate__animated animate__fadeIn animate__fast">
                        <h3>{featured[active].address}</h3>
                        <h5 className="location d-flex align-items-center">{(featured[active].location.name)} <span className="line-location d-inline-block mx-3"></span> {(featured[active].location.name)} </h5>
                        <h5 className="surface">{featured[active].surface}</h5>
                        <Link className="mt-auto" to={"/emprendimientos/" + getFakeAddress(featured[active]).replaceAll(" ","-").replaceAll("/","-").toLowerCase() + "/" + featured[active].id}>VER MÁS</Link>
                        <div className="arrows">
                            {active < (getFeatured(allDevelopmentsData)).length -1  ? (<div onClick={() => setActive(active + 1)} className="next d-flex align-items-center justify-content-center"><i className="icon-arrow-right"></i></div>) : '' }
                            {active > 0 ? <div onClick={() => setActive(active - 1)} className="back d-flex align-items-center justify-content-center"><i className="icon-arrow-right"></i></div> : ''}
                        </div>
                    </div>}
                    {/* order getFeatured(allDevelopmentsData) in desc order id */}
                    {(getFeatured(allDevelopmentsData)).map((item,index)=> (
                        active < index ? 
                        <div key={index} className="item d-none d-lg-block">
                            <h3><span>{item.address}</span></h3>
                        </div>
                        :''
                    ))}
                </div>
            </div>
    </section>
  ):''
}