import React, { useEffect } from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"

import Main from "../components/Emprendimiento/main"
import About from "../components/Emprendimiento/about"
import Units from "../components/Emprendimiento/units"

import { connect } from "react-redux"

import { useParams } from "@reach/router"
import {
  getDevelopmentAction,
  getUnitsDevelopmentAction,
} from "../redux/developmentsDucks"
import {
  getName,
  getFakeAddress,
  getLocation,
} from "../helpers/helper.developments"
import { getCover } from "../helpers/helper.rendering"
import loadable from "@loadable/component"
import { useGetDevelopmentDetailQuery } from "../redux/middlewareTokkoApi/developments"
import { useGetDevelopmentQuery } from "../redux/mediacore/emprendimientos"
import { graphql, navigate, useStaticQuery } from "gatsby"
import useQueryDevelopment from "../hooks/useQueryDevelopment"

// const Main = loadable(() => import('../../../components/Emprendimiento/main'))
const isBrowser = typeof window !== "undefined"

const Development = props => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env{
          CLIENTID
        }
      }
    }
  `)

  const { id } = props

  const {
    allDevelopmentData,
    isLoading,
    isError,
    isFetching,
  } = useQueryDevelopment(id)

  useEffect(() => {
    if (isError || (!isFetching && !isLoading && !allDevelopmentData)) {
      navigate("/404")
    }
  }, [allDevelopmentData, isLoading, isError, isFetching])

  return (
    <Layout preloader={true} development={allDevelopmentData}>
      <Seo
        title={"Emprendimiento "}
        meta={"Encontrá el emprendimiento que estabas buscando. "}
        description={"Encontrá el emprendimiento que estabas buscando. "}
      />
      <div className="Emprendimiento">
      <Main id={id} />
      <About id={id} />
      <Units id={id} />
      </div>
    </Layout>
  )
}
export default connect(state => ({}), null)(Development)

// export async function getServerData(context) {
//   try {
//     const res = await fetch(`https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/${context.params.id}?cache=true`,{headers: {"X-TOKKO-KEY": "4aeb6e6d5e8f5f041734390ce1a2a65238328ae9"}})
//     if (!res.ok) {
//       throw new Error(`Response failed`)
//     }
//     return {
//       props: await res.json(),
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {}
//     }
//   }
// }
