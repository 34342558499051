import * as React from "react"
import Seo from "../components/seo"
import { Router } from "@reach/router"

import Emprendimientos from '../templates/emprendimientos'
import EmprendimientosTag from '../templates/emprendimientos-tag'
import Emprendimiento from '../templates/emprendimiento'

const IndexPage = () => {
  return(
    <>
      <Seo title="Emprendimientos" />
      <Router basepath={"/emprendimientos"}>
        <Emprendimientos path="/" />
        <EmprendimientosTag path="/:tag" />
        <Emprendimiento path="/:fake-address/:id" />
      </Router>
    </>
  )
}
export default IndexPage